import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, Report, Property } from '@app/core';
import { map } from 'rxjs/operators';
import {Helpers} from '../shared/helpers';
@Injectable({ providedIn: 'root' })
export class ReportsPEFService {
  constructor(private http: HttpClient,
              private helper: Helpers) { }

  getAll() {
    return this.http.get<{ list: Report[] }>(`${AppConfig.apiEndpoint}/reportsPEF/1/10000`)
      .pipe(
        map(response => {
          response.list.forEach(report => {
            if (report.startDate) report.startDate = new Date(report.startDate);
            if (report.endDate) report.endDate = new Date(report.endDate);
            if (report.createdDate) report.createdDate = new Date(report.createdDate);
            if (report.updatedDate) report.updatedDate = new Date(report.updatedDate);
          });
          return response;
        })
      );
  }

  add(report: Report) {
    return this.http.post(`${AppConfig.apiEndpoint}/reportsPEF`, report);
  }

  update(report: Report) {
    return this.http.put(`${AppConfig.apiEndpoint}/reportsPEF`, report);
  }

  delete(id: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/reportsPEF/${id}`);
  }

  getAllPropertiesDropdownByEntity() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPropertiesDropdownByEntity/1/100000`);
  }

}
