import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '@app/core';
import { NotificationSchedule } from '@app/core/models/notificationSchedule';
import { UnitFile } from '@app/core/models/unitFile';

@Injectable({ providedIn: 'root' })
export class RentRollDialogService {
 
    constructor(private http: HttpClient) { }


    getRentRolls(propertyId:number) {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/RentRoll/1/10000/${propertyId}`);
      }

      downloadRentRoll(filePath: string) {
        return this.http.get(`${AppConfig.apiEndpoint}/RentRoll/getRentRollFile/${filePath}`, {responseType:'blob', observe: 'response'});
      }
    
     
      removeRentRoll(rentRollFileId:number) {
        return this.http.delete(`${AppConfig.apiEndpoint}/RentRoll/removeRentRoll/${rentRollFileId}`);
      }
      removeAllRentRolls(propertyId:number) {
        return this.http.delete(`${AppConfig.apiEndpoint}/RentRoll/removeAllRentRolls/${propertyId}`);
      }

      SendRentRollEmail(propertyId:number) {
        return this.http.put(`${AppConfig.apiEndpoint}/RentRoll/sendRentRollEmail/${propertyId}`,null);
      }


}
