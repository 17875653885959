<div class="wncModal" style="z-index: 999;">
    <div class="panelHeader">
      <div class="row">
        <div class="col-md-9 panelTitle">
          <div class="col-md-12 no-pad">
            <h3 class="white-text header-padding-left">Upload Files</h3>
          </div>
        </div>      
        <div class="col-md-3 panelButton">
          <button type="button" class="btn btn-outline-danger white-background" (click)="closeModal()"><i class="fa fa-times" aria-hidden="true"></i> &#160;Close</button>
        </div>
      </div>
    </div>  
    <div class="panelBody">
      <div style="padding-top: 10px;">
        <div class="container" appDnd (fileDropped)="onFileDropped($event)">
          <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" accept=".pdf" />
          <img src="../../../../assets/image/ic-upload-file.svg" alt="">
          <h3>Drag and drop file here</h3>
          <h3>or</h3>
          <label for="fileDropRef">Browse for file</label>
        </div>
        <div class="files-list">
          <div class="single-file" *ngFor="let file of files; let i = index">
            <img src="../../../../assets/image/ic-file.svg" width="45px" alt="file">
            <div class="info">
              <h4 class="name">
                {{ file?.name }}
              </h4>
              <p class="size">
                {{ formatBytes(file?.size) }}
              </p>
              <app-progress [progress]="file?.progress"></app-progress>
            </div>
           <!-- <img src="../../../../assets/image/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">-->
          </div>
        </div>
    </div>
  
    </div>
  </div>